<template>
  <div class="container">
    <h2>my programming skills:</h2>
    <section class="skills-container">
      <div class="skills-category" v-for="category in categories" :key="category.title">
        <h3>{{ category.title }}</h3>
        <p>{{ categories.skills }}</p>
        <ul :class="category.title">
          <li v-for="skill in category.skills" :key="skill.name">
            <img :src="skill.badge" :alt="skill.name" />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        categories: [
          {
            title: 'Frontend',
            skills: [
          { name: 'HTML5', badge: 'https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white' },
          { name: 'CSS3', badge: 'https://img.shields.io/badge/CSS3-1572B6?style=for-the-badge&logo=css3&logoColor=white' },
          { name: 'JavaScript', badge: 'https://img.shields.io/badge/JavaScript-323330?style=for-the-badge&logo=javascript&logoColor=F7DF1E' },
          { name: 'Sass', badge: 'https://img.shields.io/badge/Sass-CC6699?style=for-the-badge&logo=sass&logoColor=white' },
        ]
          },
          {
            title: 'Frameworks',
            skills: [
          { name: 'Vue.js', badge: 'https://img.shields.io/badge/Vue.js-35495E?style=for-the-badge&logo=vue.js&logoColor=4FC08D' },
          { name: 'React', badge: 'https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB' },
          { name: 'Laravel', badge: 'https://img.shields.io/badge/Laravel-FF2D20?style=for-the-badge&logo=laravel&logoColor=white' },
          { name: 'Svelte', badge: 'https://img.shields.io/badge/Svelte-FF3E00?style=for-the-badge&logo=svelte&logoColor=white' },
          { name: '.NET', badge: 'https://img.shields.io/badge/.NET-512BD4?style=for-the-badge&logo=.net&logoColor=white' },
        ]
          },
          {
            title: 'Mobile',
            skills: [
          { name: 'Kotlin', badge: 'https://img.shields.io/badge/Kotlin-0095D5?&style=for-the-badge&logo=kotlin&logoColor=white' },
        ]
          },
          {
            title: 'OS',
            skills: [
          { name: 'Linux', badge: 'https://img.shields.io/badge/Linux-FCC624?style=for-the-badge&logo=linux&logoColor=black' },
          { name: 'Windows', badge: 'https://img.shields.io/badge/Windows-0078D6?style=for-the-badge&logo=windows&logoColor=white' },
          { name: 'PowerShell', badge: 'https://img.shields.io/badge/PowerShell-5391FE?style=for-the-badge&logo=powershell&logoColor=white' },
        ]
          },
          {
            title: 'Databases',
            skills: [
          { name: 'MySQL', badge: 'https://img.shields.io/badge/MySQL-00000F?style=for-the-badge&logo=mysql&logoColor=white' },
          { name: 'MongoDB', badge: 'https://img.shields.io/badge/MongoDB-4EA94B?style=for-the-badge&logo=mongodb&logoColor=white' },
        ]
          },
          {
            title: 'Others',
            skills: [
          { name: 'Java', badge: 'https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=java&logoColor=white' },
          { name: 'Docker', badge: 'https://img.shields.io/badge/Docker-2496ED?style=for-the-badge&logo=docker&logoColor=white' },
          { name: 'Python', badge: 'https://img.shields.io/badge/Python-3776AB?style=for-the-badge&logo=python&logoColor=white' },
          { name: 'Vagrant', badge: 'https://img.shields.io/badge/Vagrant-1868F2?style=for-the-badge&logo=vagrant&logoColor=white' },
          { name: 'Git', badge: 'https://img.shields.io/badge/Git-F05032?style=for-the-badge&logo=git&logoColor=white' },
          { name: 'Postman', badge: 'https://img.shields.io/badge/Postman-FF6C37?style=for-the-badge&logo=postman&logoColor=white' },
          { name: 'Figma', badge: 'https://img.shields.io/badge/Figma-F24E1E?style=for-the-badge&logo=figma&logoColor=white' },
        ]
          },
        ],
      };
    },
  };
  </script>

<style scoped>

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-top: 20vh;
}
.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skills-category {
  flex: 1 0 21%; /* Pas dit aan om de gewenste breedte van de kolommen te krijgen */
  margin: 1em;
}

.skills-category ul {
  list-style-type: none; /* Verwijdert de standaard bullets van de lijst */
  padding: 0;
}

.skills-category li {
  display: block; /* Toont de lijst items naast elkaar */
  margin-top: 10px; /* Ruimte tussen de items */
}

</style>

<template>
  <div class="home-container">
    <section class="intro-section">
      <h1>Lucas Guillemyn</h1>
      <p>Hi, I'm passionate about programming, a 20-year-old student of Applied Computer Science with a love for full-stack development.</p>
      <a href="#about" class="about-link">About me</a>
    </section>

    <div class="spacer"></div>

    <section id="about">
    <h2>Get to <span>know</span> me</h2>
    <p>I am a 20-year-old student majoring in Applied Computer Science with a focus on Software Engineering. My passion for programming and creating new things has always driven me. I've grown up in the fascinating world of technology and have been interested in it for nearly my entire life.</p>
        
      <p>Beyond my enthusiasm for technology, I have a deep love for sports, particularly swimming. I dedicated 17 years of my life to swimming, competing at a high level for 12 of those years. In everything I'm passionate about, whether it's technology or sports, I give 100%.</p>
        
      <p>I eagerly look forward to the future and what it holds for both technology and sports. Learning new things is something I always enjoy and am continuously seeking opportunities to grow and evolve.</p>
      
      <div class="skills-list">
        <SkillsList/>    
      </div>
    </section>
    
    <div class="footer">
      
    </div>
  </div>
</template> 
  
  <script>

  import SkillsList from '@/components/SkillsList.vue';

  export default {
    name: 'UserHome',
    components: {
      SkillsList,
    }
  }

  </script>
  
  <style scoped>
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  flex-direction: column;
  background-color: var(--background-color);
  color: var(--primary-color);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 1rem;
}

.intro-section {
  margin-top: 22vh;
  text-align: left;
  max-width: 600px;
}

.intro-section h1 {
  font-size: 4rem;
  margin-bottom: 0.5em;
}

.intro-section p {
  font-size: 1.4rem;
  line-height: 1.6;
}

.about-link {
  margin-top: 20px;
  padding: 10px;
  background-color: var(--link-active-color); 
  color: var(--text-color);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.about-link:hover {
  background-color: var(--link-hover-color); 
}

.spacer{
  height: 100vh;
}

#about{
  margin: 5vh;
  text-align: left;
  max-width: 600px;
  margin-bottom: 50vh;
}

#about h2{
  font-size: 4rem;
  margin-bottom: 0.5em;
}

#about p{
  font-size: 1.4rem;
  line-height: 1.6;
}

#about span{
  color: var(--secondary-color);
}

.skills-list {
  margin-top: 22hv; /* Pas dit aan naar de gewenste waarde */
}

.footer {
  width: 100%;
  margin-top: -10vh;
}
</style>
<template>
  <header class="navbar">
    <div class="logo-container">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </router-link>
    </div>
    <nav class="nav-links">
      <router-link 
        v-for="(route, index) in navRoutes" 
        :key="route.path" 
        :to="route.path" 
        class="nav-item" 
        active-class="nav-item-active">
        {{ `${index + 1}. ${route.name}` }}
      </router-link>
    </nav>
  </header>
  <router-view/>
  <div class="footer">
    <FooterComponent/>
  </div>
</template>

<script>

import FooterComponent from '@/components/FooterComponent.vue';

export default {
  data() {
    return {
      navRoutes: [
        { name: 'Home', path: '/' },
        { name: 'Projects', path: '/projects' },
         { name: 'Blog', path: '/blogs'},
        { name: 'Contact', path: '/contact' },
        // Je kunt hier meer routes toevoegen
      ]
    };
  },
  components:{
    FooterComponent
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);; /* Verander deze waarde naar de gewenste donkere achtergrondkleur */
  padding: 1rem 2rem; /* Pas de padding aan voor grotere ruimte aan de randen */
}

.logo-container .logo img {
  height: 4vh; /* Of een andere hoogte naar voorkeur */
  width: auto; /* Dit houdt de aspect ratio van je logo in stand */
  filter: invert(1)
}

.nav-links .nav-item {
  color: var(--primary-color); /* Dit is de kleur van je nav items */
  font-weight: bold;
  margin: 0 10px; /* Geeft ruimte tussen de navigatie-items */
  text-decoration: none;
  transition: color 0.3s; /* Voegt een overgangseffect toe voor kleurverandering */
}

.nav-links .nav-item-active {
  color: var(--link-active-color); /* Kleur voor het actieve nav-item */
}

.nav-item:hover {
  color: var(--link-hover-color); /* Kleur voor hover */
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center; /* Zorgt ervoor dat alles gecentreerd is */
  }

  .nav-links {
    display: flex;
    flex-direction: column; /* Stapelt de links verticaal */
    align-items: center; /* Centreert de links */
    width: 100%; /* Zorgt ervoor dat de nav-links de volledige breedte van de navbar innemen */
    margin-top: 0.5rem;
  }

  .nav-links .nav-item {
    width: 100%; /* Zorgt ervoor dat elke nav-item de volledige breedte inneemt */
    text-align: center; /* Centreert de tekst van de nav-items */
    margin: 5px 0; /* Geeft ruimte tussen de items */
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  flex: 1 0 auto;
}

.footer {
  height: 2vh;
  margin-top: auto;
}
</style>
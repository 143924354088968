<template>
  <div class="blogs-container">
    <h1>Welcome to my blog</h1>
    <div class="blog-cards-container">
    <BlogCard
      v-for="(post, index) in posts"
      :key="index"
      :post="post"
      class="blog-post"
    />
    </div>
  </div>
</template>

<script>
import BlogCard from '@/components/BlogCard.vue';


export default {
  components: {
    BlogCard,
  },
  data() {
    return {
      posts: []
    };
  },
  mounted() {
    const requireContext = require.context('@/assets/blogs', false, /\.md$/);
  this.posts = requireContext.keys().map((fileName, index) => {
    const postData = requireContext(fileName);
    return {
      id: index, // Voeg index toe als de id
      ...postData.attributes, // Spreid de attributen van de post uit
    };
  });
  }
};
</script>

<style>
.blogs-container {
  color: var(--primary-color);
  margin: 0 auto;
  padding: 2rem;
  min-height: 81vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-heading {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--title-color);
  font-size: 2.5rem;
}

.blog-cards-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}

.blog-post {
  margin: 1rem;
}



@media (max-width: 768px) {
  .blogs-container {
    padding: 1rem;
  }
}
</style>

<template>
  <div class="contact-page">
    <div class="contact-container">
      <img src="@/assets/images/profielfoto.jpg" alt="Lucas Guillemyn" class="profile-photo" />
      <h1>Contacteer Me</h1>
      <p>Voel je vrij om contact met me op te nemen via:</p>
      <div class="social-media-links">
        <a href="https://www.instagram.com/lucas.guillemyn/" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
        <a href="https://github.com/frucassss" target="_blank" rel="noopener noreferrer">
          GitHub
        </a>
        <a href="mailto:lucas.guillemyn@icloud.com">
          E-mail
        </a>
        <a href="https://www.linkedin.com/in/lucas-guillemyn-280103g" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage'
  // Script inhoud indien nodig
};
</script>

<style scoped>
.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  background-color: var(--background-color);
  color: var(--primary-color);
}

.contact-container {
  text-align: center;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.profile-photo {
  width: 20rem; /* Pas de grootte aan naar wens */
  height: 20rem; /* Behoud een aspect ratio van 1:1 */
  margin-bottom: 1rem; /* Ruimte onder de foto */
  object-fit: contain; /* Zorg dat de foto netjes past */
}

.social-media-links a {
  display: inline-block;
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: var(--link-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social-media-links a:hover {
  background-color: var(--link-hover-color);
  color: black;
}

:root {
  --page-background-color: #f5f5f5;
  --link-color: #333;
  --link-hover-background: #007bff;
  --link-hover-color: #fff;
}
</style>

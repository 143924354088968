<template>
    <div class="blog-card">
      <div class="blog-text">
        <h3 class="blog-title">{{ post.title }}</h3>
        <p class="blog-description">{{ post.description }}</p>
        <router-link :to="{ name: 'BlogPost', params: { id: post.id } }" class="blog-read-more">Read more...</router-link>
      </div>
      <div class="blog-image-container">
        <img :src="post.image" :alt="post.title" class="blog-image" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      post: {
        type: Object,
        required: true
      },
    }
  };
  </script>
  
  <style scoped>
  .blog-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--card-background-color);
    color: var(--primary-color);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 80%;
    height: auto;
    border: 1px solid #707070;
    margin: 2 auto;
    min-height: 22rem; 
  }
  
  .blog-text {
    padding: 1rem;
    flex: 1;
  }
  
  .blog-image-container {
  flex-basis: 40%;
  display: flex; /* Zorgt voor flexbox layout */
  align-items: center; /* Centreert de afbeelding verticaal */
  justify-content: center; /* Centreert de afbeelding horizontaal */
  overflow: hidden; /* Verbergt elke overloop buiten de container */
}

.blog-image {
  max-width: 75%; /* Zorgt ervoor dat de afbeelding niet breder is dan de container */
  max-height: 15rem; /* Beperkt de hoogte van de afbeelding binnen de kaart */
  border-radius: 10px; /* Overeenkomend met de kaartradius */
  object-fit: scale-down; /* Zorgt ervoor dat de afbeelding de ruimte bedekt zonder verhoudingen te verliezen */
}
  
  .blog-title {
    font-size: 2.2rem;
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--primary-color);
  }
  
  .blog-description {
    color: var(--primary-color);
    padding: auto;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    height: 8rem;
    font-size: larger;
    display: flex;
    align-items: center;
  }
  
  .blog-read-more {
    color: var(--link-color);
    text-decoration: none;
    font-size: 0.9rem;
    margin-top: 2rem; /* Geeft ruimte boven de link */
  }
  
  .blog-read-more:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 1024px) {
  .blog-card {
    flex-direction: column; /* Stack de elementen verticaal op iPads */
    align-items: flex-start; /* Align items to the start on smaller screens */
    width: 100%; /* Laat de kaart de volledige breedte innemen op kleinere schermen */
    height: auto; /* Hoogte aanpassen aan de inhoud */
  }

  .blog-image-container {
    width: 100%; /* Laat de afbeelding de volledige breedte van de kaart innemen */
    order: 2; /* Plaats de afbeelding onder de tekst */
  }

  .blog-image {
    max-width: 100%; /* Zorg ervoor dat de afbeelding binnen de kaart past */
    height: auto; /* Behoud de aspect ratio */
    object-fit: scale-down; /* Snijd de afbeelding bij zodat deze de container vult */
  }

  .blog-text {
    order: 1; /* Plaats de tekst boven de afbeelding */
    width: 100%; /* Laat de tekst de volledige breedte van de kaart innemen */
    padding: 1rem; /* Voeg wat padding toe voor de tekst */
  }

  .blog-title {
    font-size: 1.5rem; /* Pas de grootte van de titel aan voor iPad-schermen */
  }

  .blog-description {
    font-size: 1rem; /* Pas de grootte van de beschrijving aan voor iPad-schermen */
    margin-bottom: 1rem; /* Voeg wat ruimte toe onder de beschrijving */
  }

  .blog-read-more {
    font-size: 0.9rem; /* Pas de grootte van de 'Lees meer' link aan voor iPad-schermen */
    padding: 0.5rem 0; /* Voeg wat padding toe voor de link */
  }
}

  @media (max-width: 768px) {
    .blog-card {
    flex-direction: column;
    width: 100%; /* Volledige breedte op mobiele apparaten */
    height: auto; /* Hoogte aanpassen aan de inhoud */
    border-radius: 0; /* Optioneel: pas de border-radius aan voor mobiele apparaten */
  }

  .blog-text,
  .blog-image-container {
    flex-basis: auto; /* Reset de flex-basis */
    margin: 0; /* Reset de marge */
  }

  .blog-image {
    width: 100%; /* Afbeelding neemt volledige breedte van de kaart */
    height: auto; /* Hoogte automatisch aanpassen */
    max-width: none; /* Verwijder de maximale breedte beperking */
    border-radius: 0; /* Optioneel: pas de border-radius aan voor mobiele apparaten */
    object-fit: cover; /* Zorgt ervoor dat de afbeelding de container volledig vult */
    margin-left: 0; /* Reset de linker marge */
  }

  .blog-title {
    font-size: 1.5rem; /* Kleinere titelgrootte op mobiele apparaten */
  }

  .blog-description {
    font-size: 1rem; /* Kleinere beschrijvingsgrootte op mobiele apparaten */
    height: auto; /* Hoogte automatisch aanpassen */
  }
}

/* Extra aanpassingen voor zeer kleine schermen */
@media (max-width: 480px) {
  .blog-title {
    font-size: 1.2rem;
  }

  .blog-description {
    font-size: 0.8rem;
  }

  /* Pas hier eventueel nog meer stijlen aan voor kleinere schermen */
}
  </style>
  
<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-text">
                <p>Made with JavaScript and HTML. Thank you for visiting!</p>
                <p>In Visual Studio Code</p>
                <p>Hosted on my Raspberry Pi</p>

            </div>
            <div class="social-media">
                <a href="https://www.linkedin.com/in/lucas-guillemyn-280103g" target="_blank" rel="noopener noreferrer">
                    <v-icon name="bi-linkedin" scale="2"/>
                </a>
                <a href="https://github.com/frucassss" target="_blank" rel="noopener noreferrer">
                    <v-icon name="bi-github" scale="2"/>
                </a>
                <a href="mailto:lucas.guillemyn@icloud.com">
                    <v-icon name="bi-envelope" scale="2"/>
                </a>
            </div>
        </div>
    </footer>
</template>
  
<script>
export default {
    name: 'FooterComponent'
};
</script>
  
<style scoped>
.footer {
  background-color: var(--background-color); /* of elke kleur die je verkiest */
  color: var(--primary-color);
  padding: 2rem 0; /* Verhoog de padding om de footer groter te maken */
  border-top: 3px solid #4a5568; /* voegt een bovenrand toe */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2vh;
}

.footer-content {
  width: 100%; /* Zorgt ervoor dat de interne container zich uitstrekt tot de volledige breedte van de footer */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; /* Verlaag de padding aan de zijkanten indien nodig */
}

.footer-text, .social-media {
  flex: 1; /* Zorgt ervoor dat beide elementen gelijk uitrekken */
}

.footer-text {
  text-align: left; /* Tekst uitlijnen aan de linkerkant */
  font-size: smaller;
}

.social-media {
  text-align: right; /* Iconen uitlijnen aan de rechterkant */
  display: flex;
  justify-content: flex-end; /* Zorgt ervoor dat de iconen tegen de rechterkant aan liggen */
}

.social-media a {
  margin-left: 15px; /* Voegt ruimte toe tussen de iconen */
}

.social-media a v-icon {
  width: 24px; /* Stel de gewenste grootte in */
  height: 24px;
}
</style>

  
<template>
  <div class="projects-container">
    <h1>My projects</h1>
    <div class="projects">
      <ProjectCard
        v-for="project in projects"
        :key="project.id"
        :project="project"
      />
    </div>
  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue';


export default {
  components: {
    ProjectCard,
  },
  data() {
    return {
      projects: []
    };
  },
  mounted() {
    const requireContext = require.context('@/assets/projects', false, /\.md$/);
    this.projects = requireContext.keys().map(fileName => {
      const projectData = requireContext(fileName);
      return projectData.attributes;
    });
  }
};
</script>

<style scoped>

.projects-container {
  color: var(--primary-color);
  min-height: 83vh;
}

.projects-container h1 {
  margin: 2rem;
}
.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;

}
</style>
import { createRouter, createWebHistory } from 'vue-router';
import UserHome from '../views/UserHome.vue';
import UserProjects from '../views/UserProjects.vue';
import UserContact from '../views/UserContact.vue';
import UserBlog from '../views/UserBlog.vue';

// Definieer de routes
const routes = [
  {
    path: '/',
    name: 'UserHome',
    component: UserHome
  },
  {
    path: '/projects',
    name: 'UserProjects',
    component: UserProjects
  },
  {
    path: '/contact',
    name: 'UserContact',
    component: UserContact
  },
  {
    path: '/blogs',
    name: 'UserBlog',
    component: UserBlog
  },
  {
    path: '/blog/:id',
    name: 'BlogPost',
    component: () => import('@/views/BlogPost.vue'),
    props: true
  },
];

// Maak de router instance aan met de 'history mode'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/main.css';

// Importeer OhVueIcons en de specifieke iconen die je nodig hebt
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { FaFlag, RiZhihuFill, BiLinkedin, BiGithub, BiEnvelope } from 'oh-vue-icons/icons';

// Voeg de iconen toe die je wilt gebruiken
addIcons(FaFlag, RiZhihuFill, BiLinkedin, BiGithub, BiEnvelope);

// Creëer de app instantie
const app = createApp(App);

// Registreer 'v-icon' als een globaal component
app.component('v-icon', OhVueIcon);

// Gebruik de router
app.use(router);

// Monteer de app op de #app div
app.mount('#app');

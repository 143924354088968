<template>
    <div class="project-card">
      <img :src="project.image" :alt="project.title" class="project-image" />
      <h3 class="project-title">{{ project.title }}</h3>
      <p class="project-description">{{ project.description }}</p>
      <div class="project-links">
        <a v-if="project.repo" :href="project.repo" target="_blank" rel="noopener noreferrer">
          Repository
        </a>
        <a v-if="project.client" :href="project.client" target="_blank" rel="noopener noreferrer">
          client
        </a>
        <a v-if="project.server" :href="project.server" target="_blank" rel="noopener noreferrer">
          server
        </a>
        <a v-if="project.customName && project.customUrl" :href="project.costomUrl" target="_blank" rel="noopener noreferrer">
          {{ project.customName }}
        </a>
      </div>
      <div class="project-technologies">
        <img
          v-for="tech in project.technologies"
          :key="tech.label"
          :src="`https://img.shields.io/badge/-${tech.label}-${tech.color}?style=flat-square&logo=${tech.logo}&logoColor=white`"
          :alt="tech.label"
          class="technology-badge"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      project: {
        type: Object,
        required: true,
      },
    },
    mounted(){
    }
  };
  </script>
  
  <style scoped>
.project-card {
  background-color: var(--project-card-color);
  border: 1px solid #5c5c5c;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 22rem; /* Set a fixed width */
  height: 33rem; /* Set a fixed height */
  display: flex;
  flex-direction: column;
}

.project-image {
  width: 100%;
  height: 15rem; /* Set a fixed height */
  border-radius: 8px;
  object-fit: scale-down; /* Ensure the image covers the entire space */
  margin: 0rem auto;
}

.project-title {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.project-description {
  color: var(--primary-color);
  font-size: 1rem;
  height: 5rem; /* Set a fixed height */
}

.project-links a {
  margin: 1rem 1rem;
  color: var(--link-color);
  text-decoration:underline;
  height: 2rem; /* Set a fixed height */
  font-size: 1;
  font-weight: bold;
}

.project-technologies {
  display: inline-block;
  margin-top: 2rem;
  height: 2rem; /* Set a fixed height */
  border-radius: 4px;
}

.project-technologies img{
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}
</style>
  